var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.scoring == null || _vm.scoring.length <= 0)?_c('span',{staticClass:"bullet bullet-sm mr-1 bullet-info"}):_vm._e(),(
      _vm.scoring &&
      _vm.scoring.length > 0 &&
      (_vm.scoring[0].decision == 'REJECT' || _vm.scoring[0].decision == 'REJECTED')
    )?_c('span',{staticClass:"bullet bullet-sm mr-1 bullet-danger"}):_vm._e(),(
      _vm.scoring &&
      _vm.scoring.length > 0 &&
      (_vm.scoring[0].decision == 'APPROVE' ||
        _vm.scoring[0].decision == 'PRE-APPROVE' ||
        _vm.scoring[0].decision == 'NEXT')
    )?_c('span',{staticClass:"bullet bullet-sm mr-1 bullet-success"}):_vm._e(),(
      _vm.scoring &&
      _vm.scoring.length > 0 &&
      (_vm.scoring[0].decision == 'TBD' || _vm.scoring[0].decision == 'TBD forced')
    )?_c('span',{staticClass:"bullet bullet-sm mr-1 bullet-warning"}):_vm._e(),(
      _vm.scoring &&
      _vm.scoring.length > 0 &&
      _vm.scoring[0].decision == 'YELLOW' 
    )?_c('span',{staticClass:"bullet bullet-sm mr-1  yellow-bullet"}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }