<template>
  <span>
    <span
      v-if="scoring == null || scoring.length <= 0"
      class="bullet bullet-sm mr-1 bullet-info"
    ></span>
    <span
      v-if="
        scoring &&
        scoring.length > 0 &&
        (scoring[0].decision == 'REJECT' || scoring[0].decision == 'REJECTED')
      "
      class="bullet bullet-sm mr-1 bullet-danger"
    ></span>
    <span
      v-if="
        scoring &&
        scoring.length > 0 &&
        (scoring[0].decision == 'APPROVE' ||
          scoring[0].decision == 'PRE-APPROVE' ||
          scoring[0].decision == 'NEXT')
      "
      class="bullet bullet-sm mr-1 bullet-success"
    ></span>
    <span
      v-if="
        scoring &&
        scoring.length > 0 &&
        (scoring[0].decision == 'TBD' || scoring[0].decision == 'TBD forced')
      "
      class="bullet bullet-sm mr-1 bullet-warning"
    ></span>
    <span
      v-if="
        scoring &&
        scoring.length > 0 &&
        scoring[0].decision == 'YELLOW' 
      "
      class="bullet bullet-sm mr-1  yellow-bullet"
    ></span>
  </span>
</template>

<script>
export default {
  name: "ScoringStatus",
  props: ["scoring"],
};
</script>

<style>
.yellow-bullet{
  background: rgba(237, 237, 13, 0.915);
}
</style>
