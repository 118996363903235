  <template>
    <div>
      <Table :title="$t(title)" :fields="fields" :url="url" :body="{ role: 1 }">
        <template #cell(id)="data">
          <b-link style="width:65px;" class="badge badge-light-primary"
            v-if="
              data.item.main_contact_person &&
              data.item.main_contact_person[0] &&
              data.item.main_contact_person[0].user
            "
            :to="'/investor/' + data.item.main_contact_person[0].user.id"
          >
            <span>{{ data.item.main_contact_person[0].user.id }}</span>
          </b-link>
          <span v-else>-</span>
        </template>
        <template #cell(created_at)="data">
          {{ data.item.created_at | formatDate }}
        </template>
        <template #cell(name)="data">
          <b-link
            v-if="
              data.item.main_contact_person &&
              data.item.main_contact_person[0] &&
              data.item.main_contact_person[0].user
            "
            :to="'/investor/' + data.item.main_contact_person[0].user.id"
          >
            <span
              >{{ data.item.main_contact_person[0].user.name }}
              {{ data.item.main_contact_person[0].user.last_name }}
            </span>
          </b-link>
          <span v-else>-</span>
        </template>
        <template #cell(email)="data">
          <span
            v-if="
              data.item.main_contact_person &&
              data.item.main_contact_person[0] &&
              data.item.main_contact_person[0].user
            "
            >{{ data.item.main_contact_person[0].user.email }}</span
          >
        </template>
        <template #cell(company_name)="data">
          <span style="width:55px;" class="badge badge-light-primary"
            v-if="
              data.item.main_contact_person &&
              data.item.main_contact_person[0] &&
              data.item.main_contact_person[0].user
            "
            >C-{{ data.item.main_contact_person[0].company_id }}</span
          >
          <span v-else>-</span>
        </template>

        <template #cell(status)="data">
          <span v-if="data.item && data.item.status == 1" class="text-success"
            >{{ $t("active") }}
          </span>
          <span v-else>{{ $t("inactive") }} </span>
        </template>

        <template #cell(balance)="data">
            <span v-if="data.item.balance">{{ amountFormat(data.item.balance, 'CHF') }}</span>
            <span v-else>-</span>
        </template>

          <template #cell(invested_amount)="data">
              <span v-if="data.item.invested_amount">{{ amountFormat(data.item.invested_amount, "CHF") }}</span>
              <span v-else>-</span>
          </template>

          <template #cell(outstanding_expected_net_return)="data">
              <span v-if="data.item.outstanding_expected_net_return">{{ amountFormat(data.item.outstanding_expected_net_return, "CHF") }}</span>
              <span v-else>-</span>
          </template>

        <template #cell(address)="data">
          {{ data.item.address_nr }}, {{ data.item.city }}
        </template>
      </Table>
      <!-- <ApproveModal :textContent="modalText" :title="modalTitle" /> -->
    </div>
  </template>

  <script>
  import {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BPagination,
    BTr,
    BTh,
    BButton,
    BLink,
  } from "bootstrap-vue";
  import ApproveModal from "@/components/Common/ApproveModal";
  import CustomLoader from "@/components/Common/CustomLoader";
  import Table from "@/components/Common/Table2";
  import ScoringStatus from "@/components/Company/ScoringStatus";
  import {CurrencyAmountFormat} from '@core/utils/CurrencyAmountFormat';

  export default {
    name: "CompaniesList",
    components: {
      ScoringStatus,
      ApproveModal,
      Table,
      BTable,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BPagination,
      BTr,
      BTh,
      BButton,
      BLink,
      CustomLoader,
    },
    data() {
      return {
        modalText: "",
        modalTitle: "",
        title: "investors",
        url: "/buyer/admin/list",
        options: null,
        fields: [
          { label: "created_at", align: "start", key: "created_at", sortable: true },
          { label: "id", align: "start", key: "id", sortable: true, value: "id" },
          {
            label: "company_id",
            key: "company_name",
            sortable: false,
            company_id: "company_id",
            customSlot: true,
            value: "company_name",
          },
          {
            label: "name",
            align: "start",
            key: "name",
            sortable: true,
            value: "name",
          },
          {
            label: "email",
            align: "start",
            key: "email",
            sortable: true,
            value: "email",
          },

          {
            label: "saldo_balance",
            align: "start",
            key: "balance",
            sortable: true,
            value: "balance",
          },
          {
            label: "investment_amount",
            align: "start",
            key: "invested_amount",
            sortable: true,
            value: "invested_amount",
          },
          {
            label: "outstanding_amount",
            align: "start",
            key: "outstanding_expected_net_return",
            sortable: true,
            value: "outstanding_expected_net_return",
          },

          {
            label: "status",
            key: "status",
            customSlot: true,
            sortable: false,
            value: "status",
          },
        ],
        items: [],
        search: "",
        page: 1,
        pageCount: 0,
        itemsPerPage: 12,
        status: [],
        isLoading: false,
        totalItems: 0,
        approveModalAction: null,
        updateTable: false,
      };
    },
    computed: {
      rows() {
        return this.totalItems;
      },
    },
    created() {
      this.getApiData();
    },
    methods: {
      amountFormat(amount, currency) {
        return CurrencyAmountFormat(amount, currency)
      },

      getApiData(type) {
        this.$http.post("/buyer/admin/list").then((response) => {
          this.items = response.data.value.data;
          this.totalItems = response.data.value.total;
          this.page = response.data.value.current_page;
          this.updateTable = true;
        });
      },
    },
  };
  </script>

  <style scoped>
  </style>
